export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/dashboard",
  CATALOG: "/catalog",
  VIEW_CATALOG: "/catalog/view-catalog",
  ACCELERATOR:"/accelerator",
  CLIENT_PROJECTS:"/client-projects",
  SOLUTIONS_FOR_YASH:"/solutions-for-yash",
};
export const brand = {
  NAME: "RRT Catalog",
};
