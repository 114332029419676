import { Layout, Button } from "antd";
import ContentHome from "./ContentHome";
import BreadCrumbs from "../BreadCrumbs";

const Catalog = (props) => {
  return (
    <div
    // style={{
    //   display: "flex",
    //   justifyContent: "flex-end",
    // }}
    >
      <BreadCrumbs location={props.location} />

      <ContentHome />
    </div>
  );
};
export default Catalog;
