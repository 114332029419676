import React, { useEffect, useState } from "react";
import { Button, Layout, message, Modal } from "antd";

import Card from "./ProjectCards";
import AddProject from "./AddProject";
import { fetchProjects } from "../../action/ProjectAction";

const { Content } = Layout;

const ContentHome = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCreateClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Content>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 10px 5px 0",
        }}
      >
        <Button type="primary" style={{}} onClick={handleCreateClick}>
          Add New Project
        </Button>
      </div>
      <div
        style={{
          // background: 'white',
          minHeight: 280,
        }}
      >
        <Card />
      </div>
      <Modal
        title="Add New Project"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="55%"
        style={{ marginTop: "-30px" }}
        styles={{
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        <AddProject onCancel={handleCancel} />
      </Modal>
    </Content>
  );
};

export default ContentHome;
