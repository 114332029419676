
const url = 'https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod'; //REplace with our actual URL
// const apiUrl = 'https://example'; //REplace with our actual URL

const fetchProjects = async () => {
    try {
        const apiurl = `${url}/getAllProject`
        console.log("fetchproject called after this line")
        const response = await fetch(apiurl);
        console.log("fetchproject called")
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching items:', error);
        throw error; // Re-throwing the error for further handling
    }
};



const addProject = async (newItem) => {
    try {
        const apiUrl = `${url}/projects`
        console.log("add project called")
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newItem),
        });
        if (!response.ok) {
            throw new Error('Failed to create item');
        }
        return await response.json(); 
    } catch (error) {
        console.error('Error creating item:', error);
        throw error; // Re-throw the error for further handling
    }
};

const updateProject = async (updatedItem) => {
    try {
        const apiUrl = 'https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/getProjectById?id=';
        
        const response = await fetch(`${apiUrl}${updatedItem.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedItem),
        });
        console.log(updatedItem);
        console.log(`${apiUrl}${updatedItem.id}`);
        if (!response.ok) {
            throw new Error('Failed to update item');
        }
        return await response.json(); 
    } catch (error) {
        console.error('Error updating item:', error);
        throw error; // Re-throw the error for further handling
    }
};

const deleteProject = async (id) => {
    try {
        const apiUrl = ''
        const response = await fetch(`${apiUrl}/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Failed to delete item');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deleting item:', error);
        throw error; // Re-throw the error for further handling
    }
};

export { fetchProjects, addProject, updateProject, deleteProject };