import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Paper,
  Typography,
  Grid2 as Grid,
  Alert,
  Link,
  Breadcrumbs,
  IconButton,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactMarkdown from "react-markdown";
import BreadCrumbs from "../BreadCrumbs";
import { Col, Row, Spin } from "antd";
import CodeIcon from '@mui/icons-material/Code';
import LinkIcon from '@mui/icons-material/Link';

const fetchProject = async (id) => {
  const apiurl = `https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/getProjectById?id=${id}`;
  const response = await fetch(apiurl);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

const Description = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [catalogId, setCatalogId] = useState(null);

  useEffect(() => {
    setCatalogId(location.state);
    const getProjectData = async () => {
      try {
        const data = await fetchProject(location.state);
        setProject(data);
        console.log("project", project);
      } catch (error) {
        console.error("Error fetching project:", error);
        setError("Failed to fetch project details.");
      } finally {
        setLoading(false);
      }
    };
    getProjectData();
  }, [id]);

  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };

  const cardStyle = {
    background: 'linear-gradient(145deg, #f0f0f0 0%, #ffffff 100%)',
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    padding: '20px',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
    fontFamily: 'Roboto, Arial, sans-serif',
  };

  const iconStyle = {
    marginRight: '10px',
    color: '#1976d2',
    verticalAlign: 'middle',
  };

  const content = <div style={contentStyle} />;
  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
      </div>
    );
  }

  if (error) {
    return (
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <>
      <BreadCrumbs location={props.location} />
      <div style={{ fontFamily: "Roboto", marginLeft:'55px'}}>
        <Row>
          <Col span={14}>
            <Box sx={{ padding: "8px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" color="black" sx={{marginTop:"10px"}}>
                    {project.title}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="body1" color="textSecondary"  paragraphsx={{fontFamily: 'Roboto, Arial, sans-serif'}}  sx={{marginTop:"15px"}}>
                {project.description}
              </Typography>

              <br />
              <Typography variant="h4" color="primary">
                Project Details
              </Typography>
              <div style={{textAlign:'justify'}}>
                <ReactMarkdown>{project.markdown_text}</ReactMarkdown>
              </div>
            </Box>
          </Col>
          <Col span={10}>
            <Box sx={{ padding: "8px", width: '80%', marginLeft: "10%"}}>
              <div style={{...cardStyle, marginBottom: '20px'}}>
                <Typography variant="h6" color="primary" style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
                  <CodeIcon style={iconStyle} /> Technology Details
                </Typography>
                <Typography style={{marginBottom: '10px'}}>
                  <strong>Primary Technology:</strong>{" "}
                  {project.technology_used}
                </Typography>
                <Typography>
                  <strong>Sub Technologies:</strong>{" "}
                  {project.sub_technologies_used.join(", ")}
                </Typography>
              </div>

              <div style={{...cardStyle, backgroundColor: '#f9f9f9'}}>
                <Typography variant="h6" color="primary" style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
                  <LinkIcon style={iconStyle} /> Project Links
                </Typography>
                <Typography style={{marginBottom: '10px'}}>
                  <strong>Deployed URL:</strong>{" "}
                  <Link
                    href={project.deployed_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    style={{textDecoration: 'none', fontWeight: 'bold'}}
                  >
                    Click Here
                  </Link>
                </Typography>
                <Typography>
                  <strong>Confluence Link:</strong>{" "}
                  <Link
                    href={project.confluence_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    style={{textDecoration: 'none', fontWeight: 'bold'}}
                  >
                    Click Here
                  </Link>
                </Typography>
              </div>
            </Box>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Description;