import React,{ useEffect } from "react";
import { Button, Form, Input, Select, Upload, message, Row, Col } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import MarkdownEditor from "../../util/MarkdownEditor";
import { addProject, fetchProjects } from "../../action/ProjectAction";
import { Buffer } from 'buffer';
 
const { BlobServiceClient, generateBlobSASQueryParameters, BlobSASPermissions, StorageSharedKeyCredential } = require("@azure/storage-blob");
 
const accountName = "yashrrtcatalog";
const accountKey = "QHt049Ngnk/qbJmEMNHaWmiC+VR4axs4trE62sa+tyr918w9iLHlFWGgj93dFjEPya5clI2RtxGK+ASt513i1g==";
const containerName = "thumbnail";
const { Option } = Select;
 
const AddProject = ({ onCancel }) => {
  const [form] = Form.useForm();
  const [imageList, setImageList] = React.useState([]);
  const [imageName, setImageName] = React.useState([]);
  // const [markdownContent, setMarkdownContent] = React.useState("");
  const [technologies, setTechnologies] = React.useState([]);
  const [subTechnologies, setSubTechnologies] = React.useState([]);
  const [editorValue, setEditorValue] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleEditorChange = (newValue) => {
    setEditorValue(newValue); // Update the state with the new value
  };

  function generateSASToken(containerName) {
    // const sharedKeyCredential = new StorageSharedKeyCredential(accountName, accountKey);
 
    // // Define SAS token properties
    // const sasOptions = {
    //     containerName,
    //     permissions: BlobSASPermissions.parse("rwd"),
    //     expiresOn: new Date(new Date().valueOf() + 3600 * 1000),
    // };
 
    // const sasToken = generateBlobSASQueryParameters(sasOptions, sharedKeyCredential).toString();
    const sasToken = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-28T15:13:28Z&st=2024-12-06T07:13:28Z&spr=https&sig=bq6Q2c6RPQNPpg7hFyBKoSYFuYcknWOqGsvNjBD8lKs%3D"
    return sasToken;
  }
 
 //ne chnges
 useEffect(() => {
  const fetchTechnologies = async () => {
    try {
      const response = await fetch("https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/getTechnologies");
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTechnologies(data.technologies);
      setSubTechnologies(data.subTechnologies);
    } catch (error) {
      console.error('Error fetching technologies:', error);
      message.error('Failed to fetch technologies.');
    }
  };

  fetchTechnologies();
}, []);
 
  async function uploadFileToAzure(file, imageName) {
    try {
 
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2026-01-01T15:26:24Z&st=2024-12-06T07:26:24Z&spr=https&sig=MOxTHD4j32LJAfYkBHRT5oaXhA1Yf5p8w89WCihJfXI%3D"
 
      // Create BlobServiceClient with SAS token
      const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net/?${sasToken}`);
      const containerClient = blobServiceClient.getContainerClient(containerName);
 
 
      // Generate unique blob name
      const blobName = `${Date.now()}-${imageName}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
 
      // Upload the file data (buffer or stream)
      await blockBlobClient.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type, // Automatically detect content type based on the file's MIME type
        },
      });
 
      // Return the URL of the uploaded blob
      const blobUrl = blockBlobClient.url;
      console.log("Image uploaded successfully. URL:", blobUrl);
      return blobUrl;
 
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  }
 
  function cropImage(file, targetWidth, targetHeight) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
   
        reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
   
            image.onload = () => {
                // Create a canvas element for cropping
                const canvas = document.createElement("canvas");
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                const ctx = canvas.getContext("2d");
   
                // Calculate crop dimensions to center the crop
                const aspectRatio = image.width / image.height;
                const targetAspectRatio = targetWidth / targetHeight;
   
                let sourceX = 0, sourceY = 0, sourceWidth = image.width, sourceHeight = image.height;
                if (aspectRatio > targetAspectRatio) {
                    sourceWidth = image.height * targetAspectRatio;
                    sourceX = (image.width - sourceWidth) / 2;
                } else {
                    sourceHeight = image.width / targetAspectRatio;
                    sourceY = (image.height - sourceHeight) / 2;
                }
   
                // Draw the cropped area onto the canvas
                ctx.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, targetWidth, targetHeight);
   
                // Convert the canvas into a Blob
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error("Canvas is empty"));
                    }
                }, file.type || "image/jpeg");
            };
   
            image.onerror = () => reject(new Error("Failed to load the image"));
        };
   
        reader.onerror = () => reject(new Error("Failed to read the file"));
        reader.readAsDataURL(file);
    });
  }
 
 
  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    console.log("Markdown Content:", editorValue);
    
    // if (!markdownContent) {
    //   message.error("Please add the editor text.");
    //   return;
    // }
    const blob = await uploadFileToAzure(imageName ,"shubham");
   
 
    // Format the data as required
    const formattedData = {
      title: values.projectTitle,
      project_type:values.projectType,
      description: values.description,
      image_url: blob, // The uploaded image URL
      deployed_url: values.deployedUrl,
      confluence_link: values.confluenceurl,
      markdown_text:  editorValue,
      technology_used: values.technology,
      sub_technologies_used: values.subTechnology, // Assuming it's an array, you can adjust as per requirement
     updated_by: "user@example.com", // Replace with actual user email if available
    };
    console.log("formatteddata",formattedData);
 
    const response = await addProject(formattedData);
 
 
    if(response.status == 201) {
      message.success("Project Added successfully!");
     
    }
    alert("Form added successfully");
    onCancel(); // Call the onCancel prop to close the form
    console.log("Formatted Data:", formattedData);
    // Here, you can now send formattedData to your API or perform other operations
  };
 
 
   
 
  async function handleFileChange(event) {
    let file = event.target.files[0];
    try {
      // Crop the image to match the desired card size
      const croppedBlob = await cropImage(file, 300, 200); // Change width and height as per your card size
      const croppedFile = new File([croppedBlob], file.name, { type: file.type });
      console.log("Cropped File:", croppedFile);

      setImageName(croppedFile); // Update the image to be uploaded to Azure
      message.success("Image cropped successfully!");
  } catch (error) {
      console.error("Error cropping image:", error);
      message.error("Failed to crop the image.");
  }finally {
    setIsSubmitting(false);
  }

   // console.log("prrrrr", file)

    //setImageName(file)
   
  }
 
 
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
 
 
  return (
    <>
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
    {/* Title and Description */}
    <Row gutter={50}>
      <Col span={10}>
        <Form.Item
          name="projectTitle"
          label="Project Title"
          rules={[{ required: true, message: "Please input your project title!" }]}
        >
          <Input placeholder="Enter project title" />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          name="projectType"
          label="Project Type"
          rules={[{ required: true, message: "Please input your project type!" }]}
        >
          <Select>
            <Option value='Accelerators'>Accelerators</Option>
            <Option value='Client Project'>Client Project</Option>
            <Option value='Solutions For Yash'>Solutions For Yash</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          name="imageUpload"
          onChange={handleFileChange}
          label="Upload Thumbnail Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          type="file"
        >
<Upload name="logo" action="/upload.do" listType="picture" accept="image/*">
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter ={50}>
  <Col span ={10}>
  </Col>


    </Row>

 
    {/* Technology and Sub-technology */}
    <Row gutter={50}>
      <Col span={10}>
        <Form.Item
          name="technology"
          label="Technology"
          rules={[{ required: true, message: "Please select a technology!" }]}
        >
          <Select placeholder="Select a technology">
              {technologies.map((tech) => (
                <Option key={tech.id} value={tech.name}>
                  {tech.name}
                </Option>
              ))}
            </Select>

        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item name="subTechnology" label="Sub Technology/Tags">
        <Select mode="multiple" placeholder="Select sub technologies/tags">
              {subTechnologies.map((subTech) => (
                <Option key={subTech.id} value={subTech.name}>
                  {subTech.name}
                </Option>
              ))}
            </Select>
        </Form.Item>
      </Col>
    </Row>
 
    {/* Deployed URL and Confluence link */}
    <Row gutter={50}>
      <Col span={10}>
        <Form.Item
          name="deployedUrl"
          label="Deployed URL"
          rules={[{ required: true, message: "Please input the deployed URL!", type: "url", warningOnly: true }]}
        >
          <Input placeholder="Enter deployed URL" />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          name="confluenceurl"
          label="Confluence URL"
          rules={[{ required: true, message: "Please input the Confluence URL!", type: "url", warningOnly: true }]}
        >
          <Input placeholder="Enter Confluence URL" />
        </Form.Item>
      </Col>
    </Row>
 
    {/* Description */}
    <Row>
      <Col span={20}>
        <Form.Item
          name="description"
          label="Summary"
          rules={[{ required: true, message: "Please provide a description!" }]}
        >
          <Input.TextArea placeholder="Enter project description" rows={2} />
        </Form.Item>
      </Col>
    </Row>
 
    
    <Form.Item>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button 
            type="primary" 
            htmlType="submit" 
            style={{ 
              width: "20%", 
              backgroundColor: isSubmitting ? "#4CAF50" : undefined, // Change color when submitting
              borderColor: isSubmitting ? "#4ca2af" : undefined
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
      </Form.Item>
  </Form>
  <MarkdownEditor  value={editorValue} onChange={handleEditorChange} />
 </>
  );
};
 
export default AddProject;