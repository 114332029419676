import React, { useState } from "react";
import { Layout, Dropdown, Avatar, Menu, Input, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import {
  CloseOutlined,
  HomeOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib/icons";
import { routes } from "../../util/constants";
import { NavLink, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import logo from "../../assests/img/yash-logo.3007aa0e.svg";

const { Header } = Layout;

const { Search } = Input;
export default function HeaderAdmin(props) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const[searchvalue,setsearchvalue]=useState('');

  const handleLoginRedirect = () => {
    instance.logoutRedirect();
  };
  const navItems = ["RRT Catalog", "Technologies"];
  const menuItems = new Array(3).fill(null).map((_, index) => ({
    key: index + 1,
    label: navItems[index],
  }));
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const items = [
    {
      label: (
        <Link
          to="/dashboard"
          className="ms-4 fs-4"
          style={{ textDecoration: "none" }}
        >
          <HomeOutlined style={{ fontSize: "14px" }} />
          <span
            className="ms-4 menu-item-name"
            style={{ paddingLeft: "5px", fontSize: "14px" }}
          >
            Home
          </span>
        </Link>
      ),
      key: "0",
    },

    {
      label: (
        <span className="ms-4 fs-4" onClick={handleLoginRedirect}>
          <LogoutOutlined style={{ fontSize: "14px" }} />
          <span
            className="ms-4 menu-item-name"
            style={{ paddingLeft: "5px", fontSize: "14px" }}
          >
            Sign Out
          </span>
        </span>
      ),
      key: "1",
    },
  ];

  const handleimgClick=()=>{
    navigate('/dashboard')
  }
  return (
    <Header
      className="header-admin site-layout-background"
      style={{ padding: 0 }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <img className="logo" src={logo} alt="Logo" onClick={handleimgClick}/>
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          // items={menuItems}
          style={{
            fontSize: "1.1rem",
            height: "70px ",
            fontWeight: "500",
            flex: 1,
            marginLeft: "25px",
            display: "flex",
          }}
        >
          <Menu.Item
            key={routes.ADMIN_DASHBOARD}
            // icon={<LayoutFilled />}
            className="menu-item"
          >
            <NavLink
              className="sider-links text-decoration-none"
              to={routes.ADMIN_DASHBOARD}
            >
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.CATALOG}
            // icon={<LayoutFilled />}
            className="menu-item"
          >
            <NavLink
              className="sider-links text-decoration-none"
              to={routes.CATALOG}
            >
              Catalog
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.ACCELERATOR}
            // icon={<LayoutFilled />}
            className="menu-item"
          >
            <NavLink
              className="sider-links text-decoration-none"
              to={routes.ACCELERATOR}
            >
              Accelerator
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key={routes.CLIENT_PROJECTS}
            // icon={<LayoutFilled />}
            className="menu-item"
          >
            <NavLink
              className="sider-links text-decoration-none"
              to={routes.CLIENT_PROJECTS}
            >
              Client Projects 
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.SOLUTIONS_FOR_YASH}
            // icon={<LayoutFilled />}
            className="menu-item"
          >
            <NavLink
              className="sider-links text-decoration-none"
              to={routes.SOLUTIONS_FOR_YASH}
            >
              Solutions for YASH
            </NavLink>
          </Menu.Item>
          
        </Menu>
        <div style={{ color: "#29b6f6", margin: "19px 19px 0 0" }}>
          <Search
            allowClear
            size="larger"
            placeholder="Search"
            onSearch={onSearch}
            enterButton={<SearchOutlined />}
          />
        </div>

        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <div
            className="avatar-user-details"
            onClick={(e) => e.preventDefault()}
          >
            <div className="avatar-box">
              <span className="avatar-name">
                <strong>{props.user ? props.user.name : ""}</strong>
              </span>
              <div className="avatar-img">
                <Avatar
                  style={{ backgroundColor: "#29b6f6" }}
                  size={40}
                  icon={<UserOutlined />}
                />
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
}
