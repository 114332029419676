import React, { useEffect, useState } from "react";
import { fetchProjects } from "../../action/ProjectAction";
import { message, Card, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;

const ProjectCards = (props) => {
  const [projects, setProjects] = useState([]);
  const [editprojects, seteditProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("calling fetch projects");
        const data = await fetchProjects();
        setProjects(data);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects. Please try again.");
        message.error("Failed to fetch projects. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projects]);

  const handleDescription = (id) => {
    console.log("navigating");
    console.log(id);
    navigate(`/catalog/view-catalog`, { state: id });
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };

  const content = <div style={contentStyle} />;

  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={styles.gridContainer}>
      {projects?.map((project) => (
        <div key={project.id} style={styles.gridItem}>
          <Card
            hoverable
            style={styles.card}
            cover={
              <img
                alt={project.title}
                src={project.image_url}
                style={styles.image}
                className="card-image"
              />
            }
            onClick={() => handleDescription(project.id)}
          >
            <Meta
              title={project.title}
              description={truncateText(project?.description, 15)}
            />
          </Card>
        </div>
      ))}
      <Modal
        title="Edit Project"
        open={isModalVisible}
        footer={null}
        width="55%"
        style={{ marginTop: "-30px" }}
        styles={{
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 200px)",
        }}
      />
    </div>
  );
};

const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
    gap: '20px',
    padding: '20px',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1600px',
    margin: '0 auto',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  card: {
    width: '270px',
    height: '350px',
    border: "1px solid #ccc",
    boxShadow: "0 .3rem .8rem rgba(0,0,0,.15)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "20px",
  },
  image: {
    width: "100%",
    height: "175px",
    objectFit: 'cover',
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
};

export default ProjectCards;