import React, { useEffect, useState } from "react";
// import Whatsapp from 'D:/RRT-Catalog/YASH-RRT-Catalog-Frontend/src/assets/testImage.webp';
import { fetchProjects } from "../../action/ProjectAction";
import { message, Card, Button, Modal, Spin } from "antd";
//import AddProject from './AddProject';
// import EditProject from './EditProject';
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import BreadCrumbs from "../BreadCrumbs";

const { Meta } = Card;

const ClientProject = (props) => {
  const [projects, setProjects] = useState([]);
  const [editprojects, seteditProjects] = useState([]); // State to hold the fetched projects
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("calling fetch projects");
        const data = await fetchProjects(); //To FEtch projects from API
        const projectType = data.filter(project => 
            project.project_type.toLowerCase().includes('client project')
          );
        setProjects(projectType); // Set the fetched data to state
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects. Please try again.");
        message.error("Failed to fetch projects. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);
  const handleEdit = (project) => {
    // Handle edit action (e.g., open edit modal or navigate to edit page)
    console.log(`Edit project with ID: ${project}`);
    seteditProjects(project);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDescription = (id) => {
    console.log("navigating");
    console.log(id);
    navigate(`/catalog/view-catalog`, { state: id });
  };

  const handleDelete = (projectId) => {
    // Handle delete action (e.g., call delete API)
    console.log(`Delete project with ID: ${projectId}`);
    // You can also show a confirmation dialog before deleting
  };
  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;
  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  return (
    <div>
        <BreadCrumbs location={props.location}/>
    <div className="project-container" style={styles.container}>
      {projects?.map((project) => (
        <div>
            
          <Card
            className="card-container"
            key={project.id}
            hoverable
            style={styles.card}
            cover={
              <img
                alt={project.title}
                src={project.image_url}
                style={styles.image}
                className="card-image"
              />
            }
            onClick={() => handleDescription(project.id)}
          >
            <Meta
              title={project.title}
              description={truncateText(project?.description, 12)}
            />
          </Card>
          <div style={styles.buttonContainer}>
            {/* <Button type="primary" variant="default" color='primary' onClick={() => handleEdit(project)} style={styles.button}>
              Edit
            </Button>
            <Button type="primary" variant="default" color='danger' onClick={() => handleDelete(project.id)} style={styles.button}>
              Delete
            </Button> */}
          </div>
        </div>
      ))}
      <Modal
        title="Edit Project"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="55%"
        style={{ marginTop: "-30px" }}
        styles={{
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        {/* <EditProject onproject={editprojects} onCancel={handleCancel} /> */}
      </Modal>
    </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    textAlign: "center",
    // justifyContent: 'flex-start',
    // gap:'25px',
  },
  project: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "200px",
    margin: "10px",
    padding: "10px",
    textAlign: "center",
  },
  card: {
    width: "270px",
    height: "350px",
    border: "1px solid #ccc",
    margin: "10px",
    paddingBottom: "10px",
    //boxShadow:"2px 3px 10px 5px rgba(0, 0, 0, 0.2)",
    boxShadow: "0 .3rem .8rem rgba(0,0,0,.15)",
    height: "300px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    position: "relative",
    // backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0))',
    paddingBottom: "20px",
  },
  image: {
    width: "100%",
    height: "150px",
    borderRadius: "4px",
  },
};

export default ClientProject;

// // src/projects.js
// import React from 'react';

// // Sample data for the projects
// const projectData = [
//   {
//     id: 1,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project One',
//     description: 'This is a description for Project One.',
//   },
//   {
//     id: 2,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project Two',
//     description: 'This is a description for Project Two.',
//   },
//   {
//     id: 3,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project Three',
//     description: 'This is a description for Project Three.',
//   },
// ];

// const projects = () => {
//   return (
//     <div className="project-container" style={styles.container}>
//       {projectData.map((project) => (
//         <div key={project.id} className="project" style={styles.project}>
//           <img src={project.imgSrc} alt={project.projectName} style={styles.image} />
//           <h3>{project.projectName}</h3>
//           <p>{project.description}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//   },
//   project: {
//     border: '1px solid #ccc',
//     borderRadius: '8px',
//     width: '200px',
//     margin: '10px',
//     padding: '10px',
//     textAlign: 'center',
//   },
//   image: {
//     width: '100%',
//     height: 'auto',
//     borderRadius: '4px',
//   },
// };

// export default projects;
