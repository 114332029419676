import React, { useEffect, useState } from "react";
import { fetchProjects } from "../../action/ProjectAction";
import { message, Card, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

const { Meta } = Card;

const Accelerator = (props) => {
  const [projects, setProjects] = useState([]);
  const [editprojects, seteditProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchProjects();
        const projectType = data.filter(project => 
          project.project_type.toLowerCase().includes('accelerator')
        );
        setProjects(projectType);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects. Please try again.");
        message.error("Failed to fetch projects. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (project) => {
    console.log(`Edit project with ID: ${project}`);
    seteditProjects(project);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDescription = (id) => {
    console.log("navigating");
    console.log(id);
    navigate(`/catalog/view-catalog`, { state: id });
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  if (loading) {
    return (
      <div style={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        height: "100vh" 
      }}>
        <Spin tip="Loading..." size="large" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <BreadCrumbs location={props.location}/>
      <div style={styles.container}>
        {projects?.map((project) => (
          <Card
            key={project.id}
            hoverable
            style={styles.card}
            cover={
              <img
                alt={project.title}
                src={project.image_url}
                style={styles.image}
                className="card-image"
              />
            }
            onClick={() => handleDescription(project.id)}
          >
            <Meta
              title={project.title}
              description={truncateText(project?.description, 12)}
            />
          </Card>
        ))}
      </div>

      <Modal
        title="Edit Project"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="55%"
        style={{ marginTop: "-30px" }}
        styles={{
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        {/* Commented out EditProject component */}
        {/* <EditProject onproject={editprojects} onCancel={handleCancel} /> */}
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
    gap: '20px',
    padding: '20px',
    justifyContent: 'center',
  },
  card: {
    width: '270px',
    height: '350px',
    border: "1px solid #ccc",
    boxShadow: "0 .3rem .8rem rgba(0,0,0,.15)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "20px",
  },
  image: {
    width: "100%",
    height: "175px",
    objectFit: 'cover',
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
};

export default Accelerator;