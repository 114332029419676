import React, { useState } from "react";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import ExampleTheme from "../themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "../plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { $getRoot } from "lexical";
import './util.css';
 
function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}
 
const editorConfig = {
  theme: ExampleTheme,
  onError(error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};
 
export default function MarkdownEditor({ value, onChange }) {
  const [editorText, setEditorText] = useState("");
 
  const handleChange = (editorState) => {
    // Convert editor state to Markdown
    editorState.read(() => {
      try {
        // Use $convertToMarkdownString with TRANSFORMERS
        const markdownText = $convertToMarkdownString(TRANSFORMERS);
        setEditorText(markdownText);
 
        if (onChange) {
          onChange(markdownText);
        }
      } catch (error) {
        console.error("Markdown conversion error:", error);
      }
    });
  };
 
  return (
    <div>
      <LexicalComposer initialConfig={editorConfig}>
        <div className="editor-container">
          <ToolbarPlugin />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <OnChangePlugin onChange={handleChange} />
          </div>
          {/* Optional: Markdown Preview */}
          {/* <pre>{editorText}</pre> */}
        </div>
      </LexicalComposer>
    </div>
  );
}